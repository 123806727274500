import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import {
    Layout,
    Carousel,
    ProjectSlide,
    BudgetButton,
    SplittedButton,
    HomePageControls,
} from '../../components';
import CarouselProvider from '../../hooks/carousel/CarouselProvider';
import { useTranslations } from '../../hooks';
import { navigationMapper } from '../../data';

import styles from './Home.module.css';

const defaultMetadataQuery = graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
`;

const CAROUSEL_DURATION = 10000;

export const Home = ({
    data: {
        contentful: {
            projectCollection: {
                items: projects,
            },
        },
    },
    location,
}) => {
    const { seeProjects } = useTranslations();

    const { title } = useStaticQuery(defaultMetadataQuery).site.siteMetadata;

    const allProjects = useMemo(() => projects.map(({
        title,
        smallDescription,
        photosCollection,
    }, index) => {
        const [coverPhoto] = photosCollection.items;
        const photo = {
            src: coverPhoto.url,
            alt: coverPhoto.description,
        };

        return (
            <ProjectSlide
                title={ title }
                photo={ photo }
                key={ `${index}-${title}` }
                description={ smallDescription } />
        );
    },
    ), [projects]);

    return (
        <CarouselProvider
            length={ projects.length }
            duration={ CAROUSEL_DURATION }>
            <Layout
                fullHeight
                withFooter={ false }
                location={ location }
                className={ styles.main }
                headerVariant="transparent">
                <h1 className={ styles.heading }>{ title }</h1>
                <Carousel
                    items={ allProjects }
                    className={ styles.carousel } />
                <SplittedButton
                    to={ navigationMapper.projects }
                    className={ styles.splittedButton }>
                    { seeProjects }
                </SplittedButton>
                <HomePageControls
                    items={ projects }
                    className={ styles.controls } />
                <BudgetButton
                    arrowClassName={ styles.arrow }
                    className={ styles.budgetButton } />
            </Layout>
        </CarouselProvider>
    );
};

Home.propTypes = {
    data: PropTypes.shape({
        contentful: PropTypes.shape({
            projectCollection: PropTypes.shape({
                items: PropTypes.array.isRequired,
            }).isRequired,
        }).isRequired,
    }).isRequired,
    location: PropTypes.object.isRequired,
};

export default Home;
