export const getPrevAndNextIndex = (currentIndex, length) => {
    if (length === 1) {
        return {
            prev: currentIndex,
            next: currentIndex,
        };
    }

    if (currentIndex === 0) {
        return {
            prev: length - 1,
            next: currentIndex + 1,
        };
    }

    if (currentIndex === length - 1) {
        return {
            prev: currentIndex - 1,
            next: 0,
        };
    }

    return {
        prev: currentIndex - 1,
        next: currentIndex + 1,
    };
};

export default getPrevAndNextIndex;
