import { useEffect, useState, useCallback, useRef } from 'react';

import getPrevAndNextIndex from './getPrevAndNextIndex';

const DIRECTIONS = {
    NEXT: 'next',
    PREV: 'prev',
};

export const useCarouselBehavior = ({
    length,
    duration = 2000,
    infinite = true,
    initialIndex = 0,
} = {}) => {
    if (!length) {
        throw new Error('\'length\' must be passed to useCarouselBehavior hook');
    }

    const intervalId = useRef();
    const { prev, next } = getPrevAndNextIndex(initialIndex, length);

    const [state, setState] = useState({
        prevIndex: prev,
        nextIndex: next,
        activeIndex: initialIndex,
    });

    const changeItem = useCallback((direction) => (prevState) => {
        const nextActiveIndex = direction === DIRECTIONS.NEXT ?
            prevState.nextIndex :
            prevState.prevIndex;

        const { next, prev } = getPrevAndNextIndex(nextActiveIndex, length);

        return {
            ...prevState,
            prevIndex: prev,
            nextIndex: next,
            oldIndex: prevState.activeIndex,
            activeIndex: nextActiveIndex,
        };
    }, [length]);

    const handleIntervalTick = useCallback(() => {
        setState((prevState) => {
            if (prevState.activeIndex + 1 === length && !infinite) {
                clearInterval(intervalId);

                return prevState;
            }

            return changeItem(DIRECTIONS.NEXT)(prevState);
        });
    }, [changeItem, infinite, length]);

    const resetInterval = useCallback(() => {
        clearInterval(intervalId.current);

        intervalId.current = setInterval(handleIntervalTick, duration);
    }, [duration, handleIntervalTick]);

    const nextItem = useCallback(() => {
        setState(changeItem(DIRECTIONS.NEXT));

        resetInterval();
    }, [changeItem, resetInterval]);

    const prevItem = useCallback(() => {
        setState(changeItem(DIRECTIONS.PREV));

        resetInterval();
    }, [changeItem, resetInterval]);

    useEffect(() => {
        intervalId.current = setInterval(handleIntervalTick, duration);

        return () => {
            clearInterval(intervalId.current);
        };
    }, [duration, handleIntervalTick]);

    return {
        ...state,
        nextItem,
        prevItem,
    };
};

export default useCarouselBehavior;
