import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import CarouselContext from './CarouselContext';
import useCarouselBehavior from './useCarouselBehavior';

export const CarouselProvider = ({
    length,
    duration,
    children,
}) => {
    const carousel = useCarouselBehavior({ duration, length });

    const context = useMemo(() => ({
        ...carousel,
        duration,
        length,
    }), [carousel, duration, length]);

    return (
        <CarouselContext.Provider value={ context }>
            { children }
        </CarouselContext.Provider>
    );
};

CarouselProvider.propTypes = {
    duration: PropTypes.number,
    length: PropTypes.number.isRequired,
    children: PropTypes.node.isRequired,
};

export default CarouselProvider;
